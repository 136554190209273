
<template>
    <div>
        <div v-html="html">Aguarde, carregando...</div>
        <v-btn class="no-print" @click="imprimir">Imprimir</v-btn>
    </div>
</template>

<script>
export default {
    name: 'Imprimir',

    data: () => ({
        html: '<h3>Aguarde, carregando...<h3>'
    }),

    async mounted() {
        await this.getHtmlPedido()
    },

    methods: {
        async getHtmlPedido() {
            const { emp, ped } = this.$route.params;

            await this.$http.get(`imprimir/${emp}/${ped}`).then(resp => {
                this.html = resp.data.data;
            });

            this.imprimir();
        },

        imprimir() {
            window.print();
        },
    },
}
</script>

<style scoped>
@media print {
    .no-print {
        display: none;
    }
}
</style>
